<template>
  <v-form class="tr"
          ref="row"
          :lazy-validation="true"
  >
    <div class="td">
      {{rowIndex + 1}}
    </div>
    <excel-cell
        :value="item.firstname"
        :active="(index === 0 || item.firstname === null) && isFocusedRow"
        :rowIndex="rowIndex"
        :cellIndex="0">
      <v-autocomplete
          @keydown.delete="clearSelection"
          outlined
          :ref="0"
          dense
          :rules="required"
          v-model="item.firstname"
          :items="['🇫🇷 France', '🇩🇪 Allemagne', '🇭🇺 Hongrie', '🇨🇭 Suisse', '🇧🇷 Brésil', '🇲🇦 Maroc', '🇮🇹 Italie', '🇳🇴 Norvège', '🇨🇦 Canada', '🇩🇰 Danemark']"
          hide-details="auto"
      ></v-autocomplete>
    </excel-cell>

    <excel-cell
        :value="item.lastname"
        :active="(index === 1 || item.lastname === null) && isFocusedRow"
        :rowIndex="rowIndex"
        :cellIndex="1"
    >
        <v-text-field
            outlined
            :ref="1"
            hide-details="auto"
            v-model="item.lastname"
            dense
        />
    </excel-cell>

    <excel-cell
        :value="item.civility"
        :active="(index === 2 || item.civility === null) && isFocusedRow"
        :rowIndex="rowIndex"
        :cellIndex="2"
    >
        <v-select dense
                  :ref="2"
                  @focus="$event => {$event.target.click()}"
                  outlined
                  hide-details="auto"
                  v-model="item.civility"
                  :items="['Mme', 'Mr']"
        />
    </excel-cell>

    <excel-cell
       :active="(index === 3 || item.role === null) && isFocusedRow "
       :value="item.role"
       :rowIndex="rowIndex"
       :cellIndex="3">
        <v-radio-group row v-model="item.role" :ref="3" class="pa-0 mt-0" hide-details >
          <v-radio label="Adm." value="Admin" id="3" />
          <v-radio label="User" value="Utilisateur"/>
        </v-radio-group>
    </excel-cell>

    <excel-cell
        :value="item.group"
        :active="(index === 4 || item.group === null) && isFocusedRow"
        :rowIndex="rowIndex"
        :cellIndex="4">
      <v-autocomplete
          outlined
          :ref="4"
          dense
          v-model="item.group"
          multiple
          :items="['groupe 1', 'groupe 2', 'groupe 3']"
          hide-details="auto"
      ></v-autocomplete>
    </excel-cell>

    <excel-cell
        :value="item.active ? 'Actif' : 'Inactif'"
        :active="(index === 5 || item.active === null) && isFocusedRow"
        :rowIndex="rowIndex"
        :cellIndex="5"
        @enter="item.active = !item.active"
    >
        <v-checkbox
            hide-details
            class="pa-0 ma-0"
            :ref="5"
            id="5"
            v-model="item.active"
        />
    </excel-cell>

    <excel-cell
        :value="item.email"
        :active="(index === 6 || item.email === null) && isFocusedRow"
        :rowIndex="rowIndex"
        :cellIndex="6">
        <v-text-field
          outlined
          hide-details="auto"
          :ref="6"
          v-model="item.email"
          :rules="emailRules"
          dense
        />
    </excel-cell>

    <div class="td">
      <v-btn @click="$emit('deleteRow',rowIndex)" icon color="red">
        <v-icon color="red">mdi-delete-outline</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import ExcelCell from "@/components/ExcelTable/ExcelCell";
import {ExcelBus} from "@/utils/ExcelBus";
export default {
  props:{
    data:{
      required: true
    },
    isFocusedRow:{
      required: true
    },
    rowIndex:{
      required: true
    }
  },
  name: "ExcellRow",
  components: {ExcelCell},
  data(){
    return{
      index:0,
      maxIndex:6,
      item:null,
      lastIndex:null,
      emailRules: [
        v => !!v || 'E-mail requis',
        v => /.+@.+/.test(v) || 'E-mail doit être valide',
      ],
      required:[
        v => !!v || 'Pays requis',

      ],
      valid: false
    }
  },
  methods:{
    updateFocus(){
      this.$nextTick(() => {
        if (this.isFocusedRow) {
          const el = this.$refs[this.index]
          if (el && el.$_modelEvent === 'change') {
            document.getElementById(this.index).focus()
          } else if(el){
            el.focus()
          }
        }
      })
    },
    goNext(){
      if (!this.isFocusedRow) return

      this.index++

      if (this.index === this.maxIndex + 1){
        if (this.$refs.row.validate()){
          this.$emit('newLine')
        }
        this.index--
        return
      }

      this.updateFocus()
    },
    goPrevious(){
      if (!this.isFocusedRow) return

      this.index--

      if (this.index === -1){
        this.$emit('goBefore')
        return
      }

      this.updateFocus()
    },
    clearSelection(){
      this.item.firstname = ""
    }
  },
  beforeMount() {
    ExcelBus.$on('incrementIndex', this.goNext)
    ExcelBus.$on('decrementIndex', this.goPrevious)
    ExcelBus.$on('takeFocus',({cell}) => {
      this.lastIndex = this.index
      this.index = cell

      if (this.lastIndex !== this.index)
        this.updateFocus()
    })
    this.item = this.data
  },
  mounted() {
    this.index = 0
    this.updateFocus()
    this.$refs.row.validate()
  },
  watch:{
    data(){
      this.item = this.data
    }
  }
}
</script>

<style scoped>

</style>