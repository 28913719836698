<template>
  <div class="td mw100" @click="handleClick" @keydown.enter="handleEnter">
    <slot v-if="active === true"/>
    <span v-else>
      <span v-if="typeof value === 'object'">
        <span v-for="(item, index) in value" :key="item">
          <span v-if="value.length === 1">{{item}}</span>
          <span v-else-if="index ===0">{{item}}</span>
          <span v-else>, {{item}}</span>
        </span>
      </span>
      <span v-else>{{value}}</span>
    </span>
  </div>
</template>

<script>
import {ExcelBus} from "@/utils/ExcelBus";

export default {
  props:{
    active:{
      required:true
    },
    value:{
      required:true
    },
    rowIndex:{
      required:true
    },
    cellIndex:{
      required:true
    }
  },
  name: "ExcelCell",
  methods:{
    handleClick() {
      ExcelBus.$emit('takeFocus', {row: this.rowIndex, cell: this.cellIndex})
    },
    handleEnter(){
      this.$emit('enter')
    }
  },
}
</script>

<style scoped>

</style>