<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Utilisateurs</h1>
        <v-card class="pa-4 rounded-lg" elevation="1">
          <div class="table">
            <div class="thead">
              <div class="th" style="width: 30px">Index</div>
              <div class="th" v-for="header in headers" :key="header.text">{{header.text}}</div>
              <div class="th" style="width: 30px">Action</div>
            </div>
            <ExcellRow
                v-for="(row, index) in data" :key="index"
                :data="row"
                :rowIndex="index"
                :isFocusedRow="focusedRow === index"
                @newLine="goNextLine(index)"
                @goBefore="goPreviousLine(index)"
                @deleteRow="deleteRow"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExcellRow from "@/components/ExcelTable/ExcellRow";
import {ExcelBus} from "@/utils/ExcelBus";

export default {
  name: "ExceltableDatatable",
  components: {ExcellRow},
  data: () => ({
    controlShiftKeyIsPressed: false,
    template: {
      firstname:null,
      lastname:null,
      civility:null,
      role:null,
      group:null,
      active:null,
      email:null
    },
    data:[],
    headers: [
      {
        text: "Pays",
      },
      {
        text: "Nom",
      },
      {
        text: "Civilité",
      },
      {
        text: "Rôle",
      },
      {
        text: "Groupe",
      },
      {
        text: "Actif",
      },
      {
        text: "Email",
      },
    ],
    index:1,
    focusedRow:0,
  }),
  methods:{
    goNextLine(index){
      if (index === this.data.length - 1){
        this.data.push({...this.template})
      }
      this.focusedRow = index + 1
      ExcelBus.$emit('takeFocus',{row:this.focusedRow, cell:0})

    },
    handleClick() {
      this.$emit('click', this.index)
    },
    goPreviousLine(index){
      if (index === 0) return
      this.focusedRow--
      ExcelBus.$emit('takeFocus',{row:this.focusedRow, cell:6})
    },
    deleteRow(rowIndex){
      let temp = this.data
      temp.splice(rowIndex, 1)
      if (this.focusedRow === rowIndex){
        this.$emit('takeFocus', {row:this.focusedRow--, cell:0})
      }
    },
    keyDown(event){
      if (event.key === 'Enter')
      {
        event.preventDefault()
      }
      if (event.key === 'Shift'){
        this.controlShiftKeyIsPressed = true
        return
      }
      if (event.key === 'Tab'){
        event.stopPropagation()
        event.preventDefault()
        if (this.controlShiftKeyIsPressed){
          ExcelBus.$emit('decrementIndex')
        } else {
          ExcelBus.$emit('incrementIndex')
        }
      }
    },
    keyUp(event){
      if (event.key === 'Shift'){
        this.controlShiftKeyIsPressed = false
      }
    },
  },
  beforeCreate() {
    window.addEventListener('keydown',e => this.keyDown(e))
    window.addEventListener('keyup',e => this.keyUp(e))
    ExcelBus.$on('takeFocus',({row}) => {
      this.focusedRow = row
    })
  },
  beforeDestroy() {
    window.removeEventListener('keydown',this.keyDown)
    window.removeEventListener('keyup',this.keyUp)
  },
  beforeMount() {
    this.data.push({
      id:0,
      firstname:'🇫🇷 France',
      lastname:'Dupont',
      civility:'Mr',
      role:'Utilisateur',
      group:['groupe 1'],
      active:true,
      email:'jeandupont@email.fr'
    })
    this.data.push({
      id:1,
      firstname:'🇩🇪 Allemagne',
      lastname:'un',
      civility:'Mr',
      role:'Utilisateur',
      group:[ 'groupe 2'],
      active:true,
      email:'un@email.fr'
    })
    this.data.push({
      id:2,
      firstname:'🇫🇷 France',
      lastname:'deux',
      civility:'Mr',
      role:'Utilisateur',
      group:['groupe 1'],
      active:true,
      email:'deux@email.fr'
    })
    this.data.push({
      id:3,
      firstname:'🇭🇺 Hongrie',
      lastname:'trois',
      civility:'Mr',
      role:'Utilisateur',
      group:['groupe 1'],
      active:true,
      email:'trois@email.fr'
    })
  },
}
</script>

<style scoped>

</style>