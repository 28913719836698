<template>
  <ExceltableDatatable/>
</template>

<script>
import ExceltableDatatable from "@/components/ExcelTable/ExceltableDatatable";
export default {
  components:{ExceltableDatatable}
}
</script>
